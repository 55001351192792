import { axios } from "../../lib/axios";
import { StudentWritingAchievementEntity } from "../../types";
import { setLoadingAction } from "../loading/actions";
import {
  setStudentWritingAchievementAction,
  setWritingAchievementCountAction,
} from "./actions";

export const getStudentWritingAchievements = (
  classId: string,
  page: number,
  limit: number
): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(
        `/classes/${classId}/writing/achievements?page=${page}&limit=${limit}`
      )
      .then((res) => {
        const studentWritingAchievements: StudentWritingAchievementEntity[] =
          res.data;
        dispatch(
          setStudentWritingAchievementAction(studentWritingAchievements)
        );
        dispatch(setLoadingAction(false));
      });
  };
};

export const getWritingAchievementCount = (classId: string): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(`/classes/${classId}/writing/achievements/count`)
      .then((res) => {
        const count: number = res.data;
        dispatch(setWritingAchievementCountAction(count));
        dispatch(setLoadingAction(false));
      });
  };
};
