import * as Actions from "./actions";
import initialState from "../store/initialState";

export const samplesReducer = (
  state = initialState.samples,
  action: Actions.ActionType
) => {
  switch (action.type) {
    case Actions.EDIT_SAMPLE_NAME:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.EDIT_SAMPLE_TITLE:
      return {
        ...state,
        ...action.payload,
      };
    case Actions.SET_SAMPLES:
      return action.payload;
    default:
      return state;
  }
};
