import {
  useNavigate,
  useLocation,
  useRoutes,
  RouteObject,
  Navigate,
} from "react-router-dom";
import { useEffect } from "react";
import { ClassList } from "./features/classList";
import { Login } from "./features/login";
import storage from "./utils/storage";
import {
  LongSentenceCategoryList,
  LongSentenceCategoryResults,
  LongSentenceDetails,
} from "./features/longSentenceDetails";
import {
  PhraseDetails,
  PhraseCategoryList,
  PhraseCategoryResults,
} from "./features/phraseDetails";
import { Sample } from "./features/sample";
import {
  StudentDetails,
  StudentPasswordReset,
} from "./features/studentDetails";
import { Error400, Error500, Error404 } from "./features/errors";
import { WritingDetails } from "./features/writing";

const publicRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/sample",
    element: <Sample />,
  },
];

const protectedRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Navigate replace to="/classes" />,
  },
  {
    path: "/classes",
    element: <ClassList />,
  },
  {
    path: "/classes/:class_id/phrase",
    element: <PhraseDetails />,
  },
  {
    path: "/classes/:class_id/phrase/categories",
    element: <PhraseCategoryList />,
  },
  {
    path: "/classes/:class_id/phrase/categories/:parent_type_id/:child_type_id",
    element: <PhraseCategoryResults />,
  },
  {
    path: "/classes/:class_id/long_sentence",
    element: <LongSentenceDetails />,
  },
  {
    path: "/classes/:class_id/long_sentence/categories",
    element: <LongSentenceCategoryList />,
  },
  {
    path: "/classes/:class_id/long_sentence/categories/:level_id/:theme_id",
    element: <LongSentenceCategoryResults />,
  },
  {
    path: "/classes/:class_id/writing",
    element: <WritingDetails />,
  },
  {
    path: "/students/:student_id",
    element: <StudentDetails />,
  },
  {
    path: "/students/password/reset/:student_id",
    element: <StudentPasswordReset />,
  },
  {
    path: "/400",
    element: <Error400 />,
  },
  {
    path: "/500",
    element: <Error500 />,
  },
  {
    path: "*",
    element: <Error404 />,
  },
];

const Router = () => {
  const token = storage.getAccessToken();
  const routes = token ? protectedRoutes : publicRoutes;

  const element = useRoutes(routes);
  const navigation = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!token && location.pathname !== "/") {
      navigation("/", { replace: true });
    }
  }, [location.pathname, token, navigation]);

  return element;
};

export default Router;
