import { ClassEntity } from "../../types";

export type ActionType = SetFilteredClassesAction;

interface SetFilteredClassesAction {
  type: "SET_FILTERED_CLASSES";
  payload: ClassEntity[];
}

export const SET_FILTERED_CLASSES = "SET_FILTERED_CLASSES";
export const setFilteredClassesAction = (filteredClasses: ClassEntity[]) => {
  return {
    type: SET_FILTERED_CLASSES,
    payload: filteredClasses,
  };
};
