import {
  LongSentenceAchievementEntity,
  PhraseAchievementEntity,
  StudentEntity,
  TeacherEntity,
} from "../../types";

export type ActionType =
  | SetTeacherAction
  | SetStudentAction
  | SetPhraseAchievementAction
  | SetLongSentenceAchievementAction
  | ClearStudentAction
  | ClearTeacherAction;

interface SetTeacherAction {
  type: "SET_TEACHER";
  payload: TeacherEntity;
}

interface SetStudentAction {
  type: "SET_STUDENT";
  payload: StudentEntity;
}

interface SetPhraseAchievementAction {
  type: "SET_PHRASE_ACHIEVEMENT";
  payload: PhraseAchievementEntity[];
}

interface SetLongSentenceAchievementAction {
  type: "SET_LONG_SENTENCE_ACHIEVEMENT";
  payload: LongSentenceAchievementEntity[];
}

interface ClearStudentAction {
  type: "CLEAR_STUDENT";
}

interface ClearTeacherAction {
  type: "CLEAR_TEACHER";
}

export const SET_TEACHER = "SET_TEACHER";
export const setTeacherAction = (teacher: TeacherEntity) => {
  return {
    type: SET_TEACHER,
    payload: teacher,
  };
};

export const SET_STUDENT = "SET_STUDENT";
export const setStudentAction = (student: StudentEntity) => {
  return {
    type: SET_STUDENT,
    payload: student,
  };
};

export const SET_PHRASE_ACHIEVEMENT = "SET_PHRASE_ACHIEVEMENT";
export const setPhraseAchievementAction = (
  phraseAchievements: PhraseAchievementEntity[]
) => {
  return {
    type: SET_PHRASE_ACHIEVEMENT,
    payload: phraseAchievements,
  };
};

export const SET_LONG_SENTENCE_ACHIEVEMENT = "SET_LONG_SENTENCE_ACHIEVEMENT";
export const setLongSentenceAchievementAction = (
  longSentenceAchievements: LongSentenceAchievementEntity[]
) => {
  return {
    type: SET_LONG_SENTENCE_ACHIEVEMENT,
    payload: longSentenceAchievements,
  };
};

export const CLEAR_STUDENT = "CLEAR_STUDENT";
export const clearStudentAction = () => {
  return {
    type: CLEAR_STUDENT,
  };
};

export const CLEAR_TEACHER = "CLEAR_TEACHER";
export const clearTeacherAction = () => {
  return {
    type: CLEAR_TEACHER,
  };
};
