import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPhraseCategories } from "../../../reducks/phrase/operations";
import { PhraseCategoryEntity, RootEntity } from "../../../types";
import { Category } from "./Category";

type Props = {
  className: string;
};

export const CategoryList = (props: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPhraseCategories());
  }, [dispatch]);

  const phraseCategories = useSelector(
    (state: RootEntity) => state.phrase.phraseCategories
  );

  const listRows = phraseCategories.map(
    (value: PhraseCategoryEntity, index: number) => (
      <Category
        key={index}
        parentType={{ id: value.parentType.id, name: value.parentType.name }}
        phraseCategory={value}
        className={props.className}
      />
    )
  );

  return (
    <div className="bg-white rounded-xl px-14 pt-14 pb-7 flex flex-wrap mx-24">
      <div className="w-1/6 pt-7 bg-l-blue">
        <p className="text-center text-white font-bold">カテゴリ</p>
      </div>
      <div className="w-5/6 border-t border-b border-d4 p-7">{listRows}</div>
    </div>
  );
};
