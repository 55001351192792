import { ClassEntity } from "../../types";

export type ActionType = SetClassesAction | ClearClassesAction;

interface SetClassesAction {
  type: "SET_CLASSES";
  payload: ClassEntity[];
}

interface ClearClassesAction {
  type: "CLEAR_CLASSES";
}

export const SET_CLASSES = "SET_CLASSES";
export const setClassesAction = (classes: ClassEntity[]) => {
  return {
    type: SET_CLASSES,
    payload: classes,
  };
};

export const CLEAR_CLASSES = "CLEAR_CLASSES";
export const clearClassesAction = () => {
  return {
    type: CLEAR_CLASSES,
  };
};
