import { axios } from "../../lib/axios";
import {
  PhraseCategoryEntity,
  PhraseEntity,
  StudentPhraseListeningAchievementEntity,
  StudentPhraseSpeakingAchievementEntity,
} from "../../types";
import { setLoadingAction } from "../loading/actions";
import {
  setPhraseAchievementCountAction,
  setPhraseCategoryAction,
  setPhrasesAction,
  setStudentPhraseListeningAchievementAction,
  setStudentPhraseSpeakingAchievementAction,
} from "./actions";

export const getStudentPhraseListeningAchievements = (classId: string): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(`/classes/${classId}/phrase/listening/achievements`)
      .then((res) => {
        const studentPhraseListeningAchievements: StudentPhraseListeningAchievementEntity[] =
          res.data;
        dispatch(
          setStudentPhraseListeningAchievementAction(
            studentPhraseListeningAchievements
          )
        );
        dispatch(setLoadingAction(false));
      });
  };
};

export const getStudentPhraseListeningAchievementsByCategory = (
  classId: string,
  parentTypeId: string,
  childTypeId: string
): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(
        `/classes/${classId}/phrase/parentTypes/${parentTypeId}/childTypes/${childTypeId}/listening/achievements`
      )
      .then((res) => {
        const studentPhraseListeningAchievements: StudentPhraseListeningAchievementEntity[] =
          res.data;
        dispatch(
          setStudentPhraseListeningAchievementAction(
            studentPhraseListeningAchievements
          )
        );
        dispatch(setLoadingAction(false));
      });
  };
};

export const getStudentPhraseListeningAchievementsByCategoryAndSentence = (
  classId: string,
  parentTypeId: string,
  childTypeId: string,
  phraseId: string
): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(
        `/classes/${classId}/phrase/parentTypes/${parentTypeId}/childTypes/${childTypeId}/phrases/${phraseId}/listening/achievements`
      )
      .then((res) => {
        const studentPhraseListeningAchievements: StudentPhraseListeningAchievementEntity[] =
          res.data;
        dispatch(
          setStudentPhraseListeningAchievementAction(
            studentPhraseListeningAchievements
          )
        );
        dispatch(setLoadingAction(false));
      });
  };
};

export const getStudentPhraseSpeakingAchievementsByCategory = (
  classId: string,
  parentTypeId: string,
  childTypeId: string
): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(
        `/classes/${classId}/phrase/parentTypes/${parentTypeId}/childTypes/${childTypeId}/speaking/achievements`
      )
      .then((res) => {
        const studentPhraseSpeakingAchievements: StudentPhraseSpeakingAchievementEntity[] =
          res.data;
        dispatch(
          setStudentPhraseSpeakingAchievementAction(
            studentPhraseSpeakingAchievements
          )
        );
        dispatch(setLoadingAction(false));
      });
  };
};

export const getStudentPhraseSpeakingAchievementsByCategoryAndSentence = (
  classId: string,
  parentTypeId: string,
  childTypeId: string,
  phraseId: string
): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(
        `/classes/${classId}/phrase/parentTypes/${parentTypeId}/childTypes/${childTypeId}/phrases/${phraseId}/speaking/achievements`
      )
      .then((res) => {
        const studentPhraseSpeakingAchievements: StudentPhraseSpeakingAchievementEntity[] =
          res.data;
        dispatch(
          setStudentPhraseSpeakingAchievementAction(
            studentPhraseSpeakingAchievements
          )
        );
        dispatch(setLoadingAction(false));
      });
  };
};

export const getPhraseCategories = (): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios.get("/phrase/categories").then((res) => {
      const phraseCategories: PhraseCategoryEntity[] = res.data;
      dispatch(setPhraseCategoryAction(phraseCategories));
      dispatch(setLoadingAction(false));
    });
  };
};

export const getPhrases = (parentTypeId: string, childTypeId: string): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    await axios
      .get("/phrases", {
        params: {
          parentTypeId: parentTypeId,
          childTypeId: childTypeId,
        },
      })
      .then((res) => {
        const phrases: PhraseEntity[] = res.data;
        dispatch(setPhrasesAction(phrases));
      });
  };
};

export const getPhraseAchievementCount = (studentId: string): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(`/students/${studentId}/phrase/achievements/count`)
      .then((res) => {
        const count: number = res.data;
        dispatch(setPhraseAchievementCountAction(count));
        dispatch(setLoadingAction(false));
      });
  };
};
