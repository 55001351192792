type Props = {
  message: string;
};

export const Message = (props: Props) => {
  return (
    <div className="flex justify-center items-center my-5">
      <p className="text-7xl">{props.message}</p>
    </div>
  );
};
