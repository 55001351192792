import * as Actions from "./actions";
import initialState from "../store/initialState";

export const classesReducer = (
  state = initialState.classes,
  action: Actions.ActionType
) => {
  switch (action.type) {
    case Actions.SET_CLASSES:
      return action.payload;
    case Actions.CLEAR_CLASSES:
      return initialState.classes;
    default:
      return state;
  }
};
