import { useState } from "react";
import { UseFormGetValues, UseFormRegister } from "react-hook-form";
import { FormState } from "../types";

type Props = {
  register: UseFormRegister<FormState>;
  getValues: UseFormGetValues<FormState>;
};

export const ConfirmationPasswordInputField = (props: Props) => {
  const [isRevealPassword, setIsRevealPassword] = useState(false);

  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  };

  return (
    <div className="mb-2 mt-5">
      <label
        htmlFor="password"
        className="block max-w-sm font-bold text-lg relative text-black"
      >
        設定したいパスワード（確認用）
        <input
          id="confirmationPassword"
          type={isRevealPassword ? "text" : "password"}
          className="text-base border w-full px-5 py-4 mt-1.5 rounded-md"
          required
          {...props.register("confirmationPassword", {
            validate: (value) => value === props.getValues("password"),
          })}
        />
        <span onClick={togglePassword} role="presentation">
          {isRevealPassword ? (
            <i className="icon-eye absolute is-show" />
          ) : (
            <i className="icon-eye absolute" />
          )}
        </span>
      </label>
    </div>
  );
};
