type Props = {
  options: JSX.Element[];
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const Dropdown = (props: Props) => {
  return (
    <div className="flex justify-center items-center">
      <form id="form" className="relative inline-flex flex-grow w-96 max-w-sm">
        <img
          className="rotate-180 w-2.5 h-2.5 absolute top-0 right-0 m-4 pointer-events-none"
          src="/assets/icon-arrow_up.svg"
        />
        <select
          className="w-full border border-d4 rounded-md pl-5 pr-10 py-2 bg-white focus:outline-none appearance-none cursor-pointer"
          onChange={(e) => props.handleChange(e)}
        >
          {props.options}
        </select>
      </form>
    </div>
  );
};
