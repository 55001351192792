import { Pagination } from "../../../components/Pagination";
import { StudentWritingAchievementEntity } from "../../../types";
import { WritingResultListRow } from "./WritingResultListRow";

type Props = {
  studentWritingAchievements: StudentWritingAchievementEntity[];
  onePageDisplayItems: number;
  itemSize: number;
  handleGetWritingAchievements: (page: number) => void;
};

export const WritingResultList = (props: Props) => {
  const listRows = props.studentWritingAchievements.map(
    (value: StudentWritingAchievementEntity, index: number) => (
      <WritingResultListRow key={index} studentWritingAchievement={value} />
    )
  );

  const pageCount = Math.ceil(props.itemSize / props.onePageDisplayItems);

  return (
    <div className="relative">
      <div className="bg-white rounded-xl px-10 py-5 mx-10">
        {pageCount > 1 ? (
          <div className="flex flex-row-reverse pb-3">
            <Pagination
              pageCount={pageCount}
              handleGetItem={props.handleGetWritingAchievements}
            />
          </div>
        ) : (
          <div className="pb-7"></div>
        )}
        <table className="table-fixed w-full border-collapse">
          <thead>
            <tr>
              <th className="w-1/4 h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white relative">
                生徒名
              </th>
              <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white">
                問題文
              </th>
              <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white">
                生徒の回答
              </th>
              <th className="w-1/6 h-16 px-5 leading-tight font-bold bg-blue text-white">
                回答日時
              </th>
            </tr>
          </thead>
          <tbody>{listRows}</tbody>
        </table>
      </div>
    </div>
  );
};
