import { ClassEntity } from "../../../types";
import { ListRow } from "./ListRow";

type Props = {
  classes: ClassEntity[];
};

export const ListContent = (props: Props) => {
  const listRows = props.classes.map((value: ClassEntity, index: number) => (
    <ListRow key={index} class={value} />
  ));

  return (
    <div className="bg-white rounded-xl mx-10 pl-7 pr-7 px-7 py-6 pc:px-14 pc:py-12 flex items-center">
      <table className="border-collapse w-full mx-7 my-7">
        <thead>
          <tr>
            <th className="h-16 px-3 leading-tight font-bold bg-blue text-white border-r border-white">
              クラス名
            </th>
            <th className="h-16 px-3 leading-tight font-bold bg-blue text-white border-r border-white">
              フレーズ
            </th>
            <th className="h-16 px-3 leading-tight font-bold bg-blue text-white border-r border-white">
              長文
            </th>
            <th className="h-16 px-3 leading-tight font-bold bg-blue text-white">
              ライティング
            </th>
          </tr>
        </thead>
        <tbody>{listRows}</tbody>
      </table>
    </div>
  );
};
