import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Header } from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStudent } from "../../../reducks/users/operations";
import { RootEntity } from "../../../types";
import { PageTitle } from "../../../components/PageTitle";
import { useForm } from "react-hook-form";
import { PasswordInputField } from "../components/PasswordInputField";
import { FormState } from "../types";
import { ConfirmationPasswordInputField } from "../components/confirmationPasswordInputField";
import { PasswordResetButton } from "../components/PasswordResetButton";
import { axiosTiifa } from "../../../lib/axios";
import Loading from "../../../components/Loading";
import { setLoadingAction } from "../../../reducks/loading/actions";

export const StudentPasswordReset = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormState>({
    mode: "onSubmit",
    defaultValues: {
      password: "",
      confirmationPassword: "",
    },
  });

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params.student_id) {
      navigate("/404");
    } else {
      dispatch(getStudent(params.student_id));
    }
  }, []);

  const student = useSelector((state: RootEntity) => state.users.student);
  const isLoading = useSelector((state: RootEntity) => state.isLoading);

  const onClickBackButton = () => {
    navigate(-1);
  };

  const onSubmit = handleSubmit(async (data) => {
    dispatch(setLoadingAction(true));
    const body = {
      user_id: student?.id,
      password: data.password,
    };
    await axiosTiifa
      .post(`/student/reset`, body)
      .then(() => {
        alert("パスワードをリセットしました。");
        navigate(-1);
      })
      .catch(() => {
        alert("エラーが発生しました。\nパスワードをリセットできませんでした。");
      })
      .finally(() => {
        dispatch(setLoadingAction(false));
      });
  });

  return (
    <div>
      <Loading isLoading={isLoading} />
      <PageTitle title="生徒情報 - TiiFa LS" />
      <Header />
      <div className="teacher min-h-screen bg-e5 flex">
        <div className="w-full pc:ml-4 pc:border-l border-c pc:pl-10 pb-8">
          <div className="w-60 h-14">
            <div
              className="pl-4 ml-8 -mt-4 pt-4 w-54 h-14 cursor-pointer"
              onClick={onClickBackButton}
            >
              <img
                className="transform rotate-180 inline-block mr-2 mb-1"
                src="/assets/icon-arrow.png"
                alt=""
              />
              前の画面へ戻る
            </div>
          </div>
          <h2 className="text-3xl font-bold text-center mb-3 mt-7">
            {student?.lastName + " " + student?.firstName + "のパスワード変更"}
          </h2>
          <form method="POST" onSubmit={onSubmit}>
            <div className="flex justify-center mt-10">
              <div className="w-3/4 bg-white rounded-xl px-12 pt-14 pb-11">
                <PasswordInputField register={register} />
                {errors.password && (
                  <span className="flex mb-4 text-red-700 text-sm font-bold">
                    パスワードに数字以外は利用できません、数字4桁で入力してください。
                  </span>
                )}
                <ConfirmationPasswordInputField
                  register={register}
                  getValues={getValues}
                />
                {errors.confirmationPassword && (
                  <span className="flex mb-4 text-red-700 text-sm font-bold">
                    パスワードが一致しません。
                  </span>
                )}
                <p className="text-lg font-bold mt-4 text-black">
                  ※パスワードは数字4桁で入力してください
                </p>
              </div>
            </div>
            <div className="flex justify-center mt-10">
              <PasswordResetButton />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
