import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootEntity, TeacherEntity } from "../types";
import storage from "../utils/storage";
import { push } from "redux-first-history";
import { clearTeacherAction } from "../reducks/users/actions";

export const Header = () => {
  const teacher =
    useSelector((state: RootEntity) => state.users.teacher) ??
    (storage.getTeacher as TeacherEntity);

  const dispatch = useDispatch();

  const signOut = () => {
    dispatch(clearTeacherAction());
    storage.clearTeacher();
    storage.clearAccessToken();
    storage.clearRefreshToken();
    dispatch(push("/"));
  };

  return (
    <div className="w-full p-5 pb-6 absolute">
      <div className="h-full pc:h-20 flex-grow bg-l-blue p-5 py-4 pc:py-6 flex justify-end items-center rounded-xl">
        <Link to="/" className="mr-a">
          <img className="h-10" src="/assets/logo.png" alt="" />
        </Link>
        <p className="text-white">{teacher.clientName}</p>
        <p className="pl-7 text-white border-l border-white ml-7">
          {teacher.lastName + " " + teacher.firstName}
        </p>
        <button
          className="block text-white px-4 py-2 rounded-full border border-white ml-7"
          onClick={signOut}
        >
          サインアウト
        </button>
      </div>
    </div>
  );
};
