import * as Actions from "./actions";
import initialState from "../store/initialState";

export const usersReducer = (
  state = initialState.users,
  action: Actions.ActionType
) => {
  switch (action.type) {
    case Actions.SET_TEACHER:
      return {
        ...state,
        teacher: action.payload,
      };
    case Actions.SET_STUDENT:
      return {
        ...state,
        student: action.payload,
      };
    case Actions.SET_PHRASE_ACHIEVEMENT:
      return {
        ...state,
        phraseAchievements: [...action.payload],
      };
    case Actions.SET_LONG_SENTENCE_ACHIEVEMENT:
      return {
        ...state,
        longSentenceAchievements: [...action.payload],
      };
    case Actions.CLEAR_STUDENT:
      return {
        ...state,
        student: null,
        phraseAchievements: [],
        longSentenceAchievements: [],
      };
    case Actions.CLEAR_TEACHER:
      return {
        ...state,
        teacher: null,
      };
    default:
      return state;
  }
};
