import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../components/Loading";
import { PageTitle } from "../../../components/PageTitle";
import { login } from "../../../reducks/users/operations";
import { RootEntity } from "../../../types";
import { EmailInputField } from "../components/EmailInputField";
import { Logo } from "../components/Logo";
import { NextButton } from "../components/NextButton";
import { PasswordInputField } from "../components/PasswordInputField";

type FormState = {
  email: string;
  password: string;
};

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const dispatch = useDispatch();

  const isLoading = useSelector((state: RootEntity) => state.isLoading);

  const onSubmit = handleSubmit((data) => {
    dispatch(login(data.email, data.password));
  });

  const redirectUrl = process.env.REACT_APP_ADMIN_URL;
  const tiifaDomain = process.env.REACT_APP_TIIFA_DOMAIN;

  return (
    <>
      <Loading isLoading={isLoading} />
      <PageTitle title="ログイン - TiiFa LS" />
      <div className="min-h-screen tab:py-0 bg-l-blue flex justify-center items-center">
        <Logo />
        <div className="login-form flex flex-col justify-center items-center mx-4 tab:my-8 p-5 tab:p-0">
          <div className="tab:w-1/2 pt-6">
            <h1 className="text-center text-blue font-black text-xl tab:text-2xl font-roboto pb-2 mb-9 tab:mb-5 border-b-2 border-blue">
              先生のログイン
            </h1>
            <form method="POST" onSubmit={onSubmit}>
              <input
                type="hidden"
                name="_token"
                value="LUXo9ITtMk1LXw7m5Va4nL6iYr62FHKzTaEDSXjX"
              />
              <EmailInputField register={register} />
              {errors.email && (
                <span className="flex mb-4 text-red-700 text-sm">
                  メールアドレスは必ず指定してください。
                </span>
              )}
              <PasswordInputField register={register} />
              {errors.password && (
                <span className="flex text-red-700 text-sm">
                  パスワードは必ず指定してください。
                </span>
              )}
              <NextButton />
            </form>
            <div className="flex justify-center">
              <a
                className="text-sm text-center mt-5 tab:mt-6 leading-loose"
                href={`https://${tiifaDomain}/teacher/password/reset?redirect_url=${redirectUrl}`}
              >
                <div className="flex">
                  パスワードを忘れた方はこちら
                  <img
                    className="transform rotate-90 ml-2"
                    src="/assets/icon-arrow_up.svg"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
