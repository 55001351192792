export const PasswordResetButton = () => {
  return (
    <div>
      <button
        type="submit"
        className="login-btn text-white mx-a mt-4 text-lg font-bold py-3 rounded-full px-16 block"
      >
        パスワードを再設定する
      </button>
    </div>
  );
};
