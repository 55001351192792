import Modal from "react-modal";

type Props = {
  isLoading: boolean;
};

const Loading = (props: Props) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "rgba(0, 0, 0, 0)",
    },
  };

  return (
    <Modal isOpen={props.isLoading} style={customStyles} ariaHideApp={false}>
      <div className="h-screen w-screen flex justify-center items-center">
        <div className="animate-spin h-12 w-12 border-4 loading-color rounded-full"></div>
      </div>
    </Modal>
  );
};

export default Loading;
