export const Logo = () => {
  return (
    <div className="top-5 left-12 p-4 absolute">
      <img
        className="h-56 tab:h-auto"
        src="/assets/logo-white.svg"
        alt=""
      ></img>
    </div>
  );
};
