import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Header } from "../../../components/Header";
import { LongSentenceResultList } from "../components/LongSentenceResultList";
import { PhraseResultList } from "../components/PhraseResultList";
import { useDispatch, useSelector } from "react-redux";
import {
  getLongSentenceAchievements,
  getPhraseAchievements,
  getStudent,
} from "../../../reducks/users/operations";
import { RootEntity } from "../../../types";
import { PageTitle } from "../../../components/PageTitle";
import useLocationChange from "../../../utils/location";
import { clearStudentAction } from "../../../reducks/users/actions";
import Loading from "../../../components/Loading";
import { getPhraseAchievementCount } from "../../../reducks/phrase/operations";
import { getLongSentenceAchievementCount } from "../../../reducks/longSentence/operations";

const ONE_PAGE_DISPLAY_ACIEVEMENTS = 30; //1ページに表示する学習履歴の数

export const StudentDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params.student_id) {
      navigate("/404");
    } else {
      dispatch(getStudent(params.student_id));
      dispatch(
        getPhraseAchievements(
          params.student_id,
          1,
          ONE_PAGE_DISPLAY_ACIEVEMENTS
        )
      );
      dispatch(
        getLongSentenceAchievements(
          params.student_id,
          1,
          ONE_PAGE_DISPLAY_ACIEVEMENTS
        )
      );
      dispatch(getPhraseAchievementCount(params.student_id));
      dispatch(getLongSentenceAchievementCount(params.student_id));
    }
  }, [dispatch]);

  const student = useSelector((state: RootEntity) => state.users.student);
  const phraseAchievements = useSelector(
    (state: RootEntity) => state.users.phraseAchievements
  );
  const longSentenceAchievements = useSelector(
    (state: RootEntity) => state.users.longSentenceAchievements
  );
  const isLoading = useSelector((state: RootEntity) => state.isLoading);
  const phraseAchievementCount = useSelector(
    (state: RootEntity) => state.phrase.phraseAchievementCount
  );
  const longSentenceAchievementCount = useSelector(
    (state: RootEntity) => state.longSentence.longSentenceAchievementCount
  );

  const lastName = student?.lastName ?? " ";
  const firstName = student?.firstName ?? " ";
  const lastNameKana = student?.lastNameKana ?? " ";
  const firstNameKana = student?.firstNameKana ?? " ";

  const [isPhrase, onClick] = useState(true);

  const onClickBackButton = () => {
    navigate(-1);
  };

  const onClickPhrase = () => {
    handleGetLongSentenceAchievements(1);
    onClick(() => true);
  };

  const onClickLongSentence = () => {
    handleGetPhraseAchievements(1);
    onClick(() => false);
  };

  const handleGetPhraseAchievements = (page: number) => {
    if (!params.student_id) {
      navigate("/404");
    } else {
      dispatch(
        getPhraseAchievements(
          params.student_id,
          page,
          ONE_PAGE_DISPLAY_ACIEVEMENTS
        )
      );
    }
  };

  const handleGetLongSentenceAchievements = (page: number) => {
    if (!params.student_id) {
      navigate("/404");
    } else {
      dispatch(
        getLongSentenceAchievements(
          params.student_id,
          page,
          ONE_PAGE_DISPLAY_ACIEVEMENTS
        )
      );
    }
  };

  // ページ遷移時にstoreから生徒情報を削除する
  useLocationChange(() => {
    dispatch(clearStudentAction());
  });

  return (
    <div>
      <Loading isLoading={isLoading} />
      <PageTitle title="生徒情報 - TiiFa LS" />
      <Header />
      <div className="teacher min-h-screen bg-e5 flex">
        <div className="w-full pc:ml-4 pc:border-l border-c pc:pl-10 pb-8">
          <div className="w-60 h-14">
            <div
              className="pl-4 ml-8 -mt-4 pt-4 w-54 h-14 cursor-pointer"
              onClick={onClickBackButton}
            >
              <img
                className="transform rotate-180 inline-block mr-2 mb-1"
                src="/assets/icon-arrow.png"
                alt=""
              />
              前の画面へ戻る
            </div>
          </div>
          <h2 className="h-10 text-2xl font-bold text-center mb-3">
            {lastName + " " + firstName}
          </h2>
          <div className="bg-white rounded-xl px-12 pt-5 mx-14 mb-7 flex flex-wrap">
            <div className="w-1/2 border-r border-c pc:pr-15 pr-10">
              <dl className="flex flex-wrap">
                <dt className="pc:w-1/4 w-2/5 font-bold mb-5">名前</dt>
                <dd className="pc:w-3/4 w-3/5 pl-2">
                  {lastName + " " + firstName}
                </dd>
                <dt className="pc:w-1/4 w-2/5 font-bold mb-5">パスワード</dt>
                <dd className="pc:w-3/4 w-3/5 pl-2 flex justify-between items-center h-6">
                  ****
                  <Link
                    to={`/students/password/reset/${params.student_id}`}
                    className="button flex justify-center items-center py-1 px-2 ml-5 text-sm"
                  >
                    <img
                      className="transform rotate-90 mr-2"
                      src="/assets/icon-arrow_up.svg"
                      alt=""
                    />
                    パスワードの変更
                  </Link>
                </dd>
              </dl>
            </div>
            <div className="w-1/2 pc:pl-15 pl-10">
              <dl className="flex flex-wrap">
                <dt className="pc:w-1/4 w-2/5 font-bold mb-5">ふりがな</dt>
                <dd className="pc:w-3/4 w-3/5 pl-2">
                  {lastNameKana + " " + firstNameKana}
                </dd>
                <dt className="pc:w-1/4 w-2/5 font-bold mb-5">
                  メールアドレス
                </dt>
                <dd className="pc:w-3/4 w-3/5 pl-2 break-all">
                  {student?.emailAddress}
                </dd>
              </dl>
            </div>
          </div>
          <ul className="flex justify-center mb-3">
            <li
              onClick={onClickPhrase}
              className={
                isPhrase
                  ? "text-xl font-bold text-center px-10 pb-2 border-b-6 border-blue mr-24 cursor-pointer"
                  : "text-xl font-bold text-center px-10 pb-2 border-b-6 border-bc mr-24 cursor-pointer"
              }
            >
              フレーズ
            </li>
            <li
              onClick={onClickLongSentence}
              className={
                isPhrase
                  ? "text-xl font-bold text-center px-16 pb-2 border-b-6 border-bc cursor-pointer"
                  : "text-xl font-bold text-center px-16 pb-2 border-b-6 border-blue cursor-pointer"
              }
            >
              長文
            </li>
          </ul>
          {isPhrase ? (
            <PhraseResultList
              phraseAchievements={phraseAchievements}
              onePageDisplayItems={ONE_PAGE_DISPLAY_ACIEVEMENTS}
              itemSize={phraseAchievementCount}
              handleGetPhraseAchievements={handleGetPhraseAchievements}
            />
          ) : (
            <LongSentenceResultList
              longSentenceAchievements={longSentenceAchievements}
              onePageDisplayItems={ONE_PAGE_DISPLAY_ACIEVEMENTS}
              itemSize={longSentenceAchievementCount}
              handleGetLongSentenceAchievements={
                handleGetLongSentenceAchievements
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};
