import { Link } from "react-router-dom";
import { StudentLongSentenceSpeakingAchievementEntity } from "../../../types";
import { getJSTDate } from "../../../utils/conversion";

type Props = {
  studentSpeakingAchievement: StudentLongSentenceSpeakingAchievementEntity;
};

export const SpeakingResultListRow = (props: Props) => {
  return (
    <tr>
      <td className="pr-3 h-14 leading-none text-center border-b border-r border-d4">
        <p className="flex justify-between items-center text-15">
          {props.studentSpeakingAchievement.lastName +
            " " +
            props.studentSpeakingAchievement.firstName}
          <Link
            to={`/students/${props.studentSpeakingAchievement.id}`}
            className="button flex justify-center items-center px-6 py-1 ml-10 text-sm"
          >
            <img
              className="transform rotate-90 mr-2"
              src="/assets/icon-arrow_up.svg"
              alt=""
            />
            生徒情報
          </Link>
        </p>
      </td>
      <td className="w-10 py-4 text-center border-b border-r border-d4">
        {props.studentSpeakingAchievement.todayCount + "回"}
      </td>
      <td className="w-10 py-4 text-center border-b border-r border-d4">
        {props.studentSpeakingAchievement.yesterdayCount + "回"}
      </td>
      <td className="w-10 py-4 text-center border-b border-r border-d4">
        {props.studentSpeakingAchievement.lastWeekCount + "回"}
      </td>
      <td className="w-10 py-4 text-center border-b border-r border-d4">
        {props.studentSpeakingAchievement.lastMonthCount + "回"}
      </td>
      <td className="w-10 py-4 text-center border-b border-r border-d4">
        {props.studentSpeakingAchievement.totalCount + "回"}
      </td>
      <td className="w-10 py-4 text-center border-b border-r border-d4">
        {Math.floor(props.studentSpeakingAchievement.highestScore) + "点"}
      </td>
      <td className="w-auto py-4 text-center border-b border-d4">
        {getJSTDate(props.studentSpeakingAchievement.lastExecutedAt)}
      </td>
    </tr>
  );
};
