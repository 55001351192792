import { useSelector } from "react-redux";
import { Dropdown } from "../../../components/Dropdown";
import { RootEntity } from "../../../types";

type Props = {
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const SelectYears = (props: Props) => {
  const years = useSelector((state: RootEntity) => state.year.years);

  const options = years.map((year, index) => {
    return (
      <option key={index} value={year}>
        {year + "年度"}
      </option>
    );
  });

  return (
    <div className="bg-white rounded-xl mx-10 px-8 py-4 mb-6 flex items-center">
      <p className="font-bold mr-11">年度を選択</p>
      <Dropdown options={options} handleChange={props.handleChange} />
    </div>
  );
};
