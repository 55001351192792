export const getJSTDate = (date: string): string => {
  if (date) {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1; // getMonthだと1月分少なくなって返ってくるので+1する
    const day = newDate.getDate();

    const hour = (`0` + newDate.getHours()).slice(-2);
    const minute = (`0` + newDate.getMinutes()).slice(-2);
    return `${year}年${month}月${day}日${hour}時${minute}分`;
  } else {
    return "-";
  }
};
