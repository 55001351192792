import {
  PhraseCategoryEntity,
  PhraseEntity,
  StudentPhraseListeningAchievementEntity,
  StudentPhraseSpeakingAchievementEntity,
} from "../../types";

export type ActionType =
  | SetStudentPhraseListeningAchievementAction
  | SetStudentPhraseSpeakingAchievementAction
  | SetPhraseCategoryAction
  | ClearPhraseAction
  | SetPhrasesAction
  | SetPhraseAchievementCountAction;

interface SetStudentPhraseListeningAchievementAction {
  type: "SET_STUDENT_PHRASE_LISTENING_ACHIEVEMENT";
  payload: StudentPhraseListeningAchievementEntity[];
}

interface SetStudentPhraseSpeakingAchievementAction {
  type: "SET_STUDENT_PHRASE_SPEAKING_ACHIEVEMENT";
  payload: StudentPhraseSpeakingAchievementEntity[];
}

interface SetPhraseCategoryAction {
  type: "SET_PHRASE_CATEGORY";
  payload: PhraseCategoryEntity[];
}

interface ClearPhraseAction {
  type: "CLEAR_PHRASE";
}

interface SetPhrasesAction {
  type: "SET_PHRASE";
  payload: PhraseEntity[];
}

interface SetPhraseAchievementCountAction {
  type: "SET_PHRASE_ACHIEVEMENT_COUNT";
  payload: number;
}

export const SET_STUDENT_PHRASE_LISTENING_ACHIEVEMENT =
  "SET_STUDENT_PHRASE_LISTENING_ACHIEVEMENT";
export const setStudentPhraseListeningAchievementAction = (
  studentPhraseListeningAchievements: StudentPhraseListeningAchievementEntity[]
) => {
  return {
    type: SET_STUDENT_PHRASE_LISTENING_ACHIEVEMENT,
    payload: studentPhraseListeningAchievements,
  };
};

export const SET_STUDENT_PHRASE_SPEAKING_ACHIEVEMENT =
  "SET_STUDENT_PHRASE_SPEAKING_ACHIEVEMENT";
export const setStudentPhraseSpeakingAchievementAction = (
  studentPhraseSpeakingAchievements: StudentPhraseSpeakingAchievementEntity[]
) => {
  return {
    type: SET_STUDENT_PHRASE_SPEAKING_ACHIEVEMENT,
    payload: studentPhraseSpeakingAchievements,
  };
};

export const SET_PHRASE_CATEGORY = "SET_PHRASE_CATEGORY";
export const setPhraseCategoryAction = (
  phraseCategories: PhraseCategoryEntity[]
) => {
  return {
    type: SET_PHRASE_CATEGORY,
    payload: phraseCategories,
  };
};

export const CLEAR_PHRASE = "CLEAR_PHRASE";
export const clearPhraseAction = () => {
  return {
    type: CLEAR_PHRASE,
  };
};

export const SET_PHRASE = "SET_PHRASE";
export const setPhrasesAction = (phrases: PhraseEntity[]) => {
  return {
    type: SET_PHRASE,
    payload: phrases,
  };
};

export const SET_PHRASE_ACHIEVEMENT_COUNT = "SET_PHRASE_ACHIEVEMENT_COUNT";
export const setPhraseAchievementCountAction = (count: number) => {
  return {
    type: SET_PHRASE_ACHIEVEMENT_COUNT,
    payload: count,
  };
};
