import {
  LongSentenceCategoryEntity,
  LongSentenceEntity,
  StudentLongSentenceListeningAchievementEntity,
  StudentLongSentenceSpeakingAchievementEntity,
} from "../../types";

export type ActionType =
  | SetStudentLongSentenceListeningAchievementAction
  | SetStudentLongSentenceSpeakingAchievementAction
  | SetLongSentenceCategoryAction
  | ClearLongSentenceAction
  | SetLongSentencesAction
  | SetLongSentenceAchievementCountAction;

interface SetStudentLongSentenceListeningAchievementAction {
  type: "SET_STUDENT_LONG_SENTENCE_LISTENING_ACHIEVEMENT";
  payload: StudentLongSentenceListeningAchievementEntity[];
}

interface SetStudentLongSentenceSpeakingAchievementAction {
  type: "SET_STUDENT_LONG_SENTENCE_SPEAKING_ACHIEVEMENT";
  payload: StudentLongSentenceSpeakingAchievementEntity[];
}

interface SetLongSentenceCategoryAction {
  type: "SET_LONG_SENTENCE_CATEGORY";
  payload: LongSentenceCategoryEntity[];
}

interface ClearLongSentenceAction {
  type: "CLEAR_LONG_SENTENCE";
}

interface SetLongSentencesAction {
  type: "SET_LONG_SENTENCE";
  payload: LongSentenceEntity[];
}

interface SetLongSentenceAchievementCountAction {
  type: "SET_LONG_SENTENCE_ACHIEVEMENT_COUNT";
  payload: number;
}

export const SET_STUDENT_LONG_SENTENCE_LISTENING_ACHIEVEMENT =
  "SET_STUDENT_LONG_SENTENCE_LISTENING_ACHIEVEMENT";
export const setStudentLongSentenceListeningAchievementAction = (
  studentLongSentenceListeningAchievements: StudentLongSentenceListeningAchievementEntity[]
) => {
  return {
    type: SET_STUDENT_LONG_SENTENCE_LISTENING_ACHIEVEMENT,
    payload: studentLongSentenceListeningAchievements,
  };
};

export const SET_STUDENT_LONG_SENTENCE_SPEAKING_ACHIEVEMENT =
  "SET_STUDENT_LONG_SENTENCE_SPEAKING_ACHIEVEMENT";
export const setStudentLongSentenceSpeakingAchievementAction = (
  studentLongSentenceSpeakingAchievements: StudentLongSentenceSpeakingAchievementEntity[]
) => {
  return {
    type: SET_STUDENT_LONG_SENTENCE_SPEAKING_ACHIEVEMENT,
    payload: studentLongSentenceSpeakingAchievements,
  };
};

export const SET_LONG_SENTENCE_CATEGORY = "SET_LONG_SENTENCE_CATEGORY";
export const setLongSentenceCategoryAction = (
  longSentenceCategories: LongSentenceCategoryEntity[]
) => {
  return {
    type: SET_LONG_SENTENCE_CATEGORY,
    payload: longSentenceCategories,
  };
};

export const CLEAR_LONG_SENTENCE = "CLEAR_LONG_SENTENCE";
export const clearLongSentenceAction = () => {
  return {
    type: CLEAR_LONG_SENTENCE,
  };
};

export const SET_LONG_SENTENCE = "SET_LONG_SENTENCE";
export const setLongSentencesAction = (longSentences: LongSentenceEntity[]) => {
  return {
    type: SET_LONG_SENTENCE,
    payload: longSentences,
  };
};

export const SET_LONG_SENTENCE_ACHIEVEMENT_COUNT =
  "SET_LONG_SENTENCE_ACHIEVEMENT_COUNT";
export const setLongSentenceAchievementCountAction = (count: number) => {
  return {
    type: SET_LONG_SENTENCE_ACHIEVEMENT_COUNT,
    payload: count,
  };
};
