import ReactPaginate from "react-paginate";

type Props = {
  pageCount: number;
  handleGetItem: (page: number) => void;
};

const LAST_DISPLAY_SIZE = 3; // 終端に表示する件数
const AROUND_DISPLAY_PAGES = 2; // 選択位置の前後で表示する件数

export const Pagination = (props: Props) => {
  const handlePageClick = (selectedItem: { selected: number }) => {
    const page = selectedItem.selected + 1;
    props.handleGetItem(page);
  };

  return (
    <ReactPaginate
      pageCount={props.pageCount}
      marginPagesDisplayed={LAST_DISPLAY_SIZE}
      pageRangeDisplayed={AROUND_DISPLAY_PAGES}
      onPageChange={handlePageClick}
      nextLabel="Next"
      previousLabel="Previous"
      containerClassName="list-style-none flex"
      pageClassName="relative block rounded bg-transparent py-1.5 px-3 transition-all duration-300 hover:bg-neutral-200"
      activeClassName="relative block rounded bg-transparent py-1.5 px-3 transition-all duration-300 bg-neutral-200"
      previousLinkClassName="relative block rounded bg-transparent py-1.5 px-3 transition-all duration-300 hover:bg-neutral-200"
      nextLinkClassName="relative block rounded bg-transparent py-1.5 px-3 transition-all duration-300 hover:bg-neutral-200"
    />
  );
};
