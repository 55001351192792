import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLongSentenceCategories } from "../../../reducks/longSentence/operations";
import { LongSentenceCategoryEntity, RootEntity } from "../../../types";
import { Category } from "./Category";

type Props = {
  className: string;
};

export const CategoryList = (props: Props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLongSentenceCategories());
  }, [dispatch]);

  const longSentenceCategories = useSelector(
    (state: RootEntity) => state.longSentence.longSentenceCategories
  );

  const listRows = longSentenceCategories.map(
    (value: LongSentenceCategoryEntity, index: number) => (
      <Category
        key={index}
        level={{ id: value.level.id, name: value.level.name }}
        longSentenceCategory={value}
        className={props.className}
      />
    )
  );

  return (
    <div className="bg-white rounded-xl px-14 pt-14 pb-7 flex flex-wrap mx-24">
      <div className="w-1/6 pt-7 bg-l-blue">
        <p className="text-center text-white font-bold">カテゴリ</p>
      </div>
      <div className="w-5/6 border-t border-b border-d4 p-7">{listRows}</div>
    </div>
  );
};
