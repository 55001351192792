import * as Actions from "./actions";
import initialState from "../store/initialState";

export const longSentenceReducer = (
  state = initialState.longSentence,
  action: Actions.ActionType
) => {
  switch (action.type) {
    case Actions.SET_STUDENT_LONG_SENTENCE_LISTENING_ACHIEVEMENT:
      return {
        ...state,
        studentLongSentenceListeningAchievements: [...action.payload],
      };
    case Actions.SET_STUDENT_LONG_SENTENCE_SPEAKING_ACHIEVEMENT:
      return {
        ...state,
        studentLongSentenceSpeakingAchievements: [...action.payload],
      };
    case Actions.SET_LONG_SENTENCE_CATEGORY:
      return {
        ...state,
        longSentenceCategories: [...action.payload],
      };
    case Actions.SET_LONG_SENTENCE:
      return {
        ...state,
        longSentences: [...action.payload],
      };
    case Actions.SET_LONG_SENTENCE_ACHIEVEMENT_COUNT:
      return {
        ...state,
        longSentenceAchievementCount: action.payload,
      };
    case Actions.CLEAR_LONG_SENTENCE:
      return initialState.longSentence;
    default:
      return state;
  }
};
