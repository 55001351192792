import * as Actions from "./actions";
import initialState from "../store/initialState";

export const phraseReducer = (
  state = initialState.phrase,
  action: Actions.ActionType
) => {
  switch (action.type) {
    case Actions.SET_STUDENT_PHRASE_LISTENING_ACHIEVEMENT:
      return {
        ...state,
        studentPhraseListeningAchievements: [...action.payload],
      };
    case Actions.SET_STUDENT_PHRASE_SPEAKING_ACHIEVEMENT:
      return {
        ...state,
        studentPhraseSpeakingAchievements: [...action.payload],
      };
    case Actions.SET_PHRASE_CATEGORY:
      return {
        ...state,
        phraseCategories: [...action.payload],
      };
    case Actions.SET_PHRASE:
      return {
        ...state,
        phrases: [...action.payload],
      };
    case Actions.SET_PHRASE_ACHIEVEMENT_COUNT:
      return {
        ...state,
        phraseAchievementCount: action.payload,
      };
    case Actions.CLEAR_PHRASE:
      return initialState.phrase;
    default:
      return state;
  }
};
