import { StudentWritingAchievementEntity } from "../../types";

export type ActionType =
  | SetStudentWritingAchievementAction
  | ClearWritingAction
  | SetWritingAchievementCountAction;

interface SetStudentWritingAchievementAction {
  type: "SET_STUDENT_WRITING_ACHIEVEMENT";
  payload: StudentWritingAchievementEntity[];
}

interface ClearWritingAction {
  type: "CLEAR_WRITING";
}

interface SetWritingAchievementCountAction {
  type: "SET_WRITING_ACHIEVEMENT_COUNT";
  payload: number;
}

export const SET_STUDENT_WRITING_ACHIEVEMENT =
  "SET_STUDENT_WRITING_ACHIEVEMENT";
export const setStudentWritingAchievementAction = (
  studentWritingAchievements: StudentWritingAchievementEntity[]
) => {
  return {
    type: SET_STUDENT_WRITING_ACHIEVEMENT,
    payload: studentWritingAchievements,
  };
};

export const CLEAR_WRITING = "CLEAR_WRITING";
export const clearWritingAction = () => {
  return {
    type: CLEAR_WRITING,
  };
};

export const SET_WRITING_ACHIEVEMENT_COUNT = "SET_WRITING_ACHIEVEMENT_COUNT";
export const setWritingAchievementCountAction = (count: number) => {
  return {
    type: SET_WRITING_ACHIEVEMENT_COUNT,
    payload: count,
  };
};
