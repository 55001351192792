export type ActionType = SetYearsAction | SetSelectedYearAction;

interface SetYearsAction {
  type: "SET_YEARS";
  payload: number[];
}

interface SetSelectedYearAction {
  type: "SET_SELECTED_YEAR";
  payload: number;
}

export const SET_YEARS = "SET_YEARS";
export const setYearsAction = (years: number[]) => {
  return {
    type: SET_YEARS,
    payload: years,
  };
};

export const SET_SELECTED_YEAR = "SET_SELECTED_YEAR";
export const setSelectedYearAction = (selectedYear: number) => {
  return {
    type: SET_SELECTED_YEAR,
    payload: selectedYear,
  };
};
