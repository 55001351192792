import * as Actions from "./actions";
import initialState from "../store/initialState";

export const yearReducer = (
  state = initialState.year,
  action: Actions.ActionType
) => {
  switch (action.type) {
    case Actions.SET_YEARS:
      return { ...state, years: action.payload };
    case Actions.SET_SELECTED_YEAR:
      return { ...state, selectedYear: action.payload };
    default:
      return state;
  }
};
