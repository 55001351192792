import { axios } from "../../lib/axios";
import {
  LongSentenceCategoryEntity,
  LongSentenceEntity,
  StudentLongSentenceListeningAchievementEntity,
  StudentLongSentenceSpeakingAchievementEntity,
} from "../../types";
import { setLoadingAction } from "../loading/actions";
import {
  setLongSentenceAchievementCountAction,
  setLongSentenceCategoryAction,
  setLongSentencesAction,
  setStudentLongSentenceListeningAchievementAction,
  setStudentLongSentenceSpeakingAchievementAction,
} from "./actions";

export const getStudentLongSentenceListeningAchievements = (
  classId: string
): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(`/classes/${classId}/longSentence/listening/achievements`)
      .then((res) => {
        const studentLongSentenceListeningAchievements: StudentLongSentenceListeningAchievementEntity[] =
          res.data;
        dispatch(
          setStudentLongSentenceListeningAchievementAction(
            studentLongSentenceListeningAchievements
          )
        );
        dispatch(setLoadingAction(false));
      });
  };
};

export const getStudentLongSentenceListeningAchievementsByCategory = (
  classId: string,
  levelId: string,
  themeId: string
): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(
        `/classes/${classId}/longSentence/levels/${levelId}/themes/${themeId}/listening/achievements`
      )
      .then((res) => {
        const studentLongSentenceListeningAchievements: StudentLongSentenceListeningAchievementEntity[] =
          res.data;
        dispatch(
          setStudentLongSentenceListeningAchievementAction(
            studentLongSentenceListeningAchievements
          )
        );
        dispatch(setLoadingAction(false));
      });
  };
};

export const getStudentLongSentenceListeningAchievementsByCategoryAndSentence =
  (
    classId: string,
    levelId: string,
    themeId: string,
    longSentenceId: string
  ): any => {
    return async (dispatch: React.Dispatch<unknown>) => {
      dispatch(setLoadingAction(true));
      await axios
        .get(
          `/classes/${classId}/longSentence/levels/${levelId}/themes/${themeId}/longSentences/${longSentenceId}/listening/achievements`
        )
        .then((res) => {
          const studentLongSentenceListeningAchievements: StudentLongSentenceListeningAchievementEntity[] =
            res.data;
          dispatch(
            setStudentLongSentenceListeningAchievementAction(
              studentLongSentenceListeningAchievements
            )
          );
          dispatch(setLoadingAction(false));
        });
    };
  };

export const getStudentLongSentenceSpeakingAchievementsByCategory = (
  classId: string,
  levelId: string,
  themeId: string
): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(
        `/classes/${classId}/longSentence/levels/${levelId}/themes/${themeId}/speaking/achievements`
      )
      .then((res) => {
        const studentLongSentenceSpeakingAchievements: StudentLongSentenceSpeakingAchievementEntity[] =
          res.data;
        dispatch(
          setStudentLongSentenceSpeakingAchievementAction(
            studentLongSentenceSpeakingAchievements
          )
        );
        dispatch(setLoadingAction(false));
      });
  };
};

export const getStudentLongSentenceSpeakingAchievementsByCategoryAndSentence = (
  classId: string,
  levelId: string,
  themeId: string,
  longSentenceId: string
): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(
        `/classes/${classId}/longSentence/levels/${levelId}/themes/${themeId}/longSentences/${longSentenceId}/speaking/achievements`
      )
      .then((res) => {
        const studentLongSentenceSpeakingAchievements: StudentLongSentenceSpeakingAchievementEntity[] =
          res.data;
        dispatch(
          setStudentLongSentenceSpeakingAchievementAction(
            studentLongSentenceSpeakingAchievements
          )
        );
        dispatch(setLoadingAction(false));
      });
  };
};

export const getLongSentenceCategories = (): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios.get("/longSentence/categories").then((res) => {
      const longSentenceCategories: LongSentenceCategoryEntity[] = res.data;
      dispatch(setLongSentenceCategoryAction(longSentenceCategories));
      dispatch(setLoadingAction(false));
    });
  };
};

export const getLongSentences = (levelId: string, themeId: string): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    await axios
      .get("/longSentences", {
        params: {
          levelId: levelId,
          themeId: themeId,
        },
      })
      .then((res) => {
        const longSentences: LongSentenceEntity[] = res.data;
        dispatch(setLongSentencesAction(longSentences));
      });
  };
};

export const getLongSentenceAchievementCount = (studentId: string): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(`/students/${studentId}/longSentence/achievements/count`)
      .then((res) => {
        const count: number = res.data;
        dispatch(setLongSentenceAchievementCountAction(count));
        dispatch(setLoadingAction(false));
      });
  };
};
