import { useState } from "react";
import useSound from "use-sound";
import { PhraseAchievementEntity } from "../../../types";
import { getJSTDate } from "../../../utils/conversion";

type Props = {
  phraseAchievement: PhraseAchievementEntity;
};

export const PhraseResultListRow = (props: Props) => {
  const [play, { pause }] = useSound(
    props.phraseAchievement.recordedAudioFileUrl!,
    {
      onend: () => {
        setIsPlaying(false);
      },
    }
  );

  const [isPlaying, setIsPlaying] = useState(false);

  const onClickPlay = () => {
    play();
    setIsPlaying(true);
  };

  const onClickPause = () => {
    pause();
    setIsPlaying(false);
  };

  return (
    <tr>
      <td className="px-2 pr-3 h-14 leading-none text-center border-b border-r border-d4">
        {props.phraseAchievement.parentType.name}
      </td>
      <td className="px-2 py-4 text-center border-b border-r border-d4">
        {props.phraseAchievement.childType.name}
      </td>
      <td className="px-2 w-auto py-4 text-center border-b border-r border-d4">
        {props.phraseAchievement.sentence}
      </td>
      <td className="px-2 py-4 text-center border-b border-r border-d4">
        {props.phraseAchievement.listeningOrSpeaking}
      </td>
      <td className="px-2 py-4 text-center border-b border-r border-d4">
        {getJSTDate(props.phraseAchievement.startedAt)}
      </td>
      <td className="px-2 py-4 text-center border-b border-r border-d4">
        {props.phraseAchievement.count + "回"}
      </td>
      <td className="px-2 py-4 text-center border-b border-d4">
        <div className="flex justify-center items-center text-15">
          {!props.phraseAchievement.score
            ? "-"
            : Math.floor(props.phraseAchievement.score) + "点"}
          {!props.phraseAchievement.recordedAudioFileUrl ? null : isPlaying ? (
            <div className="flex justify-center items-center text-15">
              <img
                onClick={onClickPause}
                className="ml-7 w-10 cursor-pointer"
                src="/assets/icon-pause.png"
                alt=""
              />
            </div>
          ) : (
            <div className="flex justify-center items-center text-15">
              <img
                onClick={onClickPlay}
                className="ml-7 w-10 cursor-pointer"
                src="/assets/icon-play.png"
                alt=""
              />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};
