export const NextButton = () => {
  return (
    <div>
      <button
        type="submit"
        className="text-center mt-5 px-24 py-3 rounded-md bg-blue text-white text-lg font-bold m-auto block"
      >
        送信
      </button>
    </div>
  );
};
