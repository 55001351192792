import * as Actions from "./actions";
import initialState from "../store/initialState";

export const filteredClassesReducer = (
  state = initialState.filteredClasses,
  action: Actions.ActionType
) => {
  switch (action.type) {
    case Actions.SET_FILTERED_CLASSES:
      return action.payload;
    default:
      return state;
  }
};
