import { TeacherEntity } from "../types";

const storagePrefix = "audio_admin_";

const storage = {
  getAccessToken: () => {
    if (window.localStorage.getItem(`${storagePrefix}access_token`)) {
      return JSON.parse(
        window.localStorage.getItem(`${storagePrefix}access_token`) as string
      );
    } else {
      return null;
    }
  },
  setAccessToken: (token: string) => {
    window.localStorage.setItem(
      `${storagePrefix}access_token`,
      JSON.stringify(token)
    );
  },
  clearAccessToken: () => {
    window.localStorage.removeItem(`${storagePrefix}access_token`);
  },
  getRefreshToken: () => {
    if (window.localStorage.getItem(`${storagePrefix}refresh_token`)) {
      return JSON.parse(
        window.localStorage.getItem(`${storagePrefix}refresh_token`) as string
      );
    } else {
      return null;
    }
  },
  setRefreshToken: (token: string) => {
    window.localStorage.setItem(
      `${storagePrefix}refresh_token`,
      JSON.stringify(token)
    );
  },
  clearRefreshToken: () => {
    window.localStorage.removeItem(`${storagePrefix}refresh_token`);
  },
  getTeacher: JSON.parse(
    window.localStorage.getItem(`${storagePrefix}teacher`) as string
  ),
  setTeacher: (teacher: TeacherEntity) => {
    window.localStorage.setItem(
      `${storagePrefix}teacher`,
      JSON.stringify(teacher)
    );
  },
  clearTeacher: () => {
    window.localStorage.removeItem(`${storagePrefix}teacher`);
  },
};

export default storage;
