import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Header } from "../../../components/Header";
import { ListeningResultList } from "../components/ListeningResultList";
import {
  getLongSentences,
  getStudentLongSentenceListeningAchievementsByCategory,
  getStudentLongSentenceListeningAchievementsByCategoryAndSentence,
  getStudentLongSentenceSpeakingAchievementsByCategory,
  getStudentLongSentenceSpeakingAchievementsByCategoryAndSentence,
} from "../../../reducks/longSentence/operations";
import { LongSentenceEntity, RootEntity } from "../../../types";
import { PageTitle } from "../../../components/PageTitle";
import useLocationChange from "../../../utils/location";
import { clearLongSentenceAction } from "../../../reducks/longSentence/actions";
import { Dropdown } from "../../../components/Dropdown";
import Loading from "../../../components/Loading";
import { SpeakingResultList } from "../components/SpeakingResultList";

type State = {
  className: string;
  levelName: string;
  themeName: string;
};

export const LongSentenceCategoryResults = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params.class_id || !params.level_id || !params.theme_id) {
      navigate("/404");
    } else {
      dispatch(
        getStudentLongSentenceListeningAchievementsByCategory(
          params.class_id,
          params.level_id,
          params.theme_id
        )
      );
      dispatch(
        getStudentLongSentenceSpeakingAchievementsByCategory(
          params.class_id,
          params.level_id,
          params.theme_id
        )
      );
      dispatch(getLongSentences(params.level_id, params.theme_id));
    }
  }, [dispatch]);

  const studentListeningAchievements = useSelector(
    (state: RootEntity) =>
      state.longSentence.studentLongSentenceListeningAchievements
  );

  const studentSpeakingAchievements = useSelector(
    (state: RootEntity) =>
      state.longSentence.studentLongSentenceSpeakingAchievements
  );

  const longSentences = useSelector(
    (state: RootEntity) => state.longSentence.longSentences
  );

  //dropdownで表示するsentence一覧
  const options = longSentences.map(
    (longSentence: LongSentenceEntity, index: number) => {
      return (
        <option key={index + 1} value={longSentence.id}>
          {longSentence.sentence}
        </option>
      );
    }
  );
  options.unshift(
    <option key={0} value="">
      全ての英文
    </option>
  );

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!params.class_id || !params.level_id || !params.theme_id) {
      navigate("/404");
    } else {
      // valueが空文字の場合はすべての英文
      if (e.target.value === "") {
        dispatch(
          getStudentLongSentenceListeningAchievementsByCategory(
            params.class_id,
            params.level_id,
            params.theme_id
          )
        );
        dispatch(
          getStudentLongSentenceSpeakingAchievementsByCategory(
            params.class_id,
            params.level_id,
            params.theme_id
          )
        );
      } else {
        dispatch(
          getStudentLongSentenceListeningAchievementsByCategoryAndSentence(
            params.class_id,
            params.level_id,
            params.theme_id,
            e.target.value
          )
        );
        dispatch(
          getStudentLongSentenceSpeakingAchievementsByCategoryAndSentence(
            params.class_id,
            params.level_id,
            params.theme_id,
            e.target.value
          )
        );
      }
    }
  };
  const isLoading = useSelector((state: RootEntity) => state.isLoading);

  const location = useLocation();
  const state = location.state as State;

  const [isListening, onClick] = useState(true);

  const onClickListening = () => {
    onClick(() => true);
  };

  const onClickSpeaking = () => {
    onClick(() => false);
  };

  // ページ遷移時にstoreから長文情報を削除する
  useLocationChange(() => {
    dispatch(clearLongSentenceAction());
  });

  return (
    <div>
      <Loading isLoading={isLoading} />
      <PageTitle title="カテゴリ別長文詳細 - TiiFa LS" />
      <Header />
      <div className="teacher min-h-screen bg-e5 flex">
        <div className="w-full pc:ml-4 pc:border-l border-c pc:pl-10 pb-8">
          <div className="w-60 h-14">
            <Link
              to={`/classes/${params.class_id}/long_sentence/categories`}
              state={{ className: state.className }}
            >
              <div className="pl-4 ml-8 -mt-4 pt-4 w-54 h-14">
                <img
                  className="transform rotate-180 inline-block mr-2 mb-1"
                  src="/assets/icon-arrow.png"
                  alt=""
                />
                前の画面へ戻る
              </div>
            </Link>
          </div>
          <h2 className="text-2xl font-bold text-center mb-3">
            {`${state.className} 長文 学習状況`}
          </h2>
          <h3 className="text-xl font-bold text-center mb-3">
            {`${state.levelName} ${state.themeName}`}
          </h3>
          <Dropdown options={options} handleChange={handleChange} />
          <ul className="flex justify-center mt-5 mb-3">
            <li
              onClick={onClickListening}
              className={
                isListening
                  ? "text-xl font-bold text-center px-8 pb-2 border-b-6 border-blue mr-24 cursor-pointer"
                  : "text-xl font-bold text-center px-8 pb-2 border-b-6 border-bc mr-24 cursor-pointer"
              }
            >
              リスニング
            </li>
            <li
              onClick={onClickSpeaking}
              className={
                isListening
                  ? "text-xl font-bold text-center px-6 pb-2 border-b-6 border-bc cursor-pointer"
                  : "text-xl font-bold text-center px-6 pb-2 border-b-6 border-blue cursor-pointer"
              }
            >
              スピーキング
            </li>
          </ul>
          {isListening ? (
            <ListeningResultList
              studentListeningAchievements={studentListeningAchievements}
            />
          ) : (
            <SpeakingResultList
              studentSpeakingAchievements={studentSpeakingAchievements}
            />
          )}
        </div>
      </div>
    </div>
  );
};
