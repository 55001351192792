import { Link } from "react-router-dom";
import { ClassEntity } from "../../../types";

type Props = {
  class: ClassEntity;
};

export const ListRow = (props: Props) => {
  return (
    <tr>
      <td className="w-auto px-5 py-4 text-center border-b border-r border-d4">
        {props.class.name}
      </td>
      <td className="w-80 px-24 py-4 text-center border-b border-r border-d4">
        <Link
          to={`/classes/${props.class.id}/phrase`}
          state={{ className: props.class.name }}
          className="button flex justify-center items-center px-4 py-1 text-sm"
        >
          <img
            className="transform rotate-90 mr-2"
            src="/assets/icon-arrow_up.svg"
            alt=""
          ></img>
          学習状況
        </Link>
      </td>
      <td className="w-80 px-24 py-4 text-center border-b border-r border-d4">
        <Link
          to={`/classes/${props.class.id}/long_sentence`}
          state={{ className: props.class.name }}
          className="button flex justify-center items-center px-4 py-1 text-sm"
        >
          <img
            className="transform rotate-90 mr-2"
            src="/assets/icon-arrow_up.svg"
            alt=""
          ></img>
          学習状況
        </Link>
      </td>
      <td className="w-80 px-24 py-4 text-center border-b border-d4">
        <Link
          to={`/classes/${props.class.id}/writing`}
          state={{ className: props.class.name }}
          className="button flex justify-center items-center px-4 py-1 text-sm"
        >
          <img
            className="transform rotate-90 mr-2"
            src="/assets/icon-arrow_up.svg"
            alt=""
          ></img>
          学習状況
        </Link>
      </td>
    </tr>
  );
};
