import { useState } from "react";
import { PhraseCategoryEntity } from "../../../types";
import { CategoryItem } from "./CategoryItem";

type Props = {
  parentType: { id: string; name: string };
  phraseCategory: PhraseCategoryEntity;
  className: string;
};

export const Category = (props: Props) => {
  const [isOpened, onClick] = useState(false);

  const toggleIcon = () => {
    onClick((prevState) => !prevState);
  };

  const listRows = props.phraseCategory.parentType.childTypes.map(
    (value: { id: string; name: string }, index: number) => (
      <CategoryItem
        key={index}
        parentType={props.parentType}
        childType={value}
        className={props.className}
      />
    )
  );

  return (
    <div>
      <p
        className="flex justify-between bg-d4 bg-opacity-40 border-b border-d4 px-7 py-5 font-bold mb-4 cursor-pointer"
        onClick={toggleIcon}
      >
        {props.phraseCategory.parentType.name}
        <img
          className={
            isOpened
              ? "transform transition-transform duration-500 ease-in-out w-3 rotate-180"
              : "transform transition-transform duration-500 ease-in-out w-3"
          }
          src="/assets/icon-arrow_up.svg"
          alt=""
        />
      </p>
      <div className={isOpened ? "mb-4" : "hidden mb-4"}>{listRows}</div>
    </div>
  );
};
