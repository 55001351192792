import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { Header } from "../../../components/Header";
import Loading from "../../../components/Loading";
import { PageTitle } from "../../../components/PageTitle";
import { clearLongSentenceAction } from "../../../reducks/longSentence/actions";
import useLocationChange from "../../../utils/location";
import { RootEntity } from "../../../types";
import { CategoryList } from "../components/CategoryList";

type State = {
  className: string;
};

export const LongSentenceCategoryList = () => {
  const params = useParams();

  const location = useLocation();
  const state = location.state as State;

  const dispatch = useDispatch();

  // ページ遷移時にstoreから長文情報を削除する
  useLocationChange(() => {
    dispatch(clearLongSentenceAction());
  });

  const isLoading = useSelector((state: RootEntity) => state.isLoading);

  return (
    <div>
      <Loading isLoading={isLoading} />
      <PageTitle title="長文 カテゴリ選択 - TiiFa LS" />
      <Header />
      <div className="teacher min-h-screen bg-e5 flex">
        <div className="w-full pc:ml-4 pc:border-l border-c pc:pl-10 pb-8">
          <div className="w-60 h-14">
            <Link
              to={`/classes/${params.class_id}/long_sentence`}
              state={{ className: state.className }}
            >
              <div className="pl-4 ml-8 -mt-4 pt-4 w-54 h-14">
                <img
                  className="transform rotate-180 inline-block mr-2 mb-1"
                  src="/assets/icon-arrow.png"
                  alt=""
                />
                前の画面へ戻る
              </div>
            </Link>
          </div>
          <h2 className="text-2xl font-bold text-center mb-4">
            {`${state.className} 長文 カテゴリ選択`}
          </h2>
          <CategoryList className={state.className} />
        </div>
      </div>
    </div>
  );
};
