import Axios, { AxiosRequestConfig } from "axios";
import { TIIFA_API_URL, AUDIO_API_URL } from "../config";
import storage from "../utils/storage";
import { history } from "../reducks/store/store";

const authRequestInterceptor = (config: AxiosRequestConfig) => {
  const token = storage.getAccessToken();
  if (token) {
    config.headers!.authorization = `Bearer ${token}`;
  }
  config.headers!.Accept = "application/json";
  return config;
};

export const axiosTiifa = Axios.create({
  baseURL: TIIFA_API_URL,
});

export const axios = Axios.create({
  baseURL: AUDIO_API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // ステータスコード別にエラー処理
    switch (error.response?.status) {
      case 400:
        history.replace("/400");
        return Promise.reject(error.response?.data);
      case 401: {
        // access tokenの有効期限が切れるとtokenを更新して再リクエストする
        const originalConfig = error.config;
        if (!originalConfig._retry) {
          originalConfig._retry = true;
          const refreshToken = storage.getRefreshToken();
          try {
            const res = await axiosTiifa.post(
              "/auth/refresh",
              {},
              {
                headers: { Authorization: `Bearer ${refreshToken}` },
              }
            );
            storage.setAccessToken(res.data.access_token);
            storage.setRefreshToken(res.data.refresh_token);
            return await axios(originalConfig);
          } catch (_) {
            // refresh tokenの有効期限が切れるとログイン画面に戻る
            storage.clearTeacher();
            storage.clearAccessToken();
            storage.clearRefreshToken();
            window.location.href = "/";
          }
        }
        return Promise.reject(error.response?.datas);
      }
      case 404:
        history.replace("/400");
        return Promise.reject(error.response?.data);
      case 500:
        history.replace("/500");
        return Promise.reject(error.response?.data);
      default:
        return Promise.reject(error.response?.data);
    }
  }
);

axiosTiifa.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
