import * as Actions from "./actions";
import initialState from "../store/initialState";

export const writingReducer = (
  state = initialState.writing,
  action: Actions.ActionType
) => {
  switch (action.type) {
    case Actions.SET_STUDENT_WRITING_ACHIEVEMENT:
      return {
        ...state,
        studentWritingAchievements: [...action.payload],
      };
    case Actions.SET_WRITING_ACHIEVEMENT_COUNT:
      return {
        ...state,
        writingAchievementCount: action.payload,
      };
    case Actions.CLEAR_WRITING:
      return initialState.writing;
    default:
      return state;
  }
};
