import { useNavigate } from "react-router-dom";
import { Header } from "../../../components/Header";
import { Message } from "../components/Message";
import { StatusCode } from "../components/StatusCode";
import { SubMessage } from "../components/SubMessage";
import { TiifaIcon } from "../components/TiifaIcon";

export const Error404 = () => {
  const navigate = useNavigate();
  const onClickBackButton = () => {
    navigate(-1);
  };

  return (
    <div>
      <Header />
      <div
        className="pl-4 ml-8 mt-24 pt-4 absolute w-40 h-14 cursor-pointer"
        onClick={onClickBackButton}
      >
        <img
          className="transform rotate-180 inline-block mr-2 mb-0.5"
          src="/assets/icon-arrow.png"
          alt=""
        />
        前の画面へ戻る
      </div>
      <div className="h-screen w-screen flex justify-center items-center bg-e5">
        <div>
          <StatusCode statusCode="404" />
          <Message message="Page not Found" />
          <SubMessage subMessage="このページは見つかりませんでした" />
          <TiifaIcon />
        </div>
      </div>
    </div>
  );
};
