import { push } from "redux-first-history";
import { axios, axiosTiifa } from "../../lib/axios";
import {
  LongSentenceAchievementEntity,
  PhraseAchievementEntity,
  StudentEntity,
  TeacherEntity,
} from "../../types";
import storage from "../../utils/storage";
import { setLoadingAction } from "../loading/actions";
import {
  setLongSentenceAchievementAction,
  setPhraseAchievementAction,
  setStudentAction,
  setTeacherAction,
} from "./actions";

export const login = (email: string, password: string): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axiosTiifa
      .post("/auth/login", {
        password: password,
        email_or_user_name: email,
      })
      .then((res) => {
        // user_typeが2の場合は先生と判断する（https://gitlab.com/ks-rogers/tiifa/laravel/-/blob/develop/app/Enums/UserType.php）
        if (res.data.user_type === 2) {
          storage.setAccessToken(res.headers["x-tiifa-token"]);
          storage.setRefreshToken(res.headers["x-tiifa-refresh-token"]);
          const teacher: TeacherEntity = {
            lastName: res.data.last_name,
            firstName: res.data.first_name,
            clientName: res.data.client.name,
          };
          storage.setTeacher(teacher);
          dispatch(setTeacherAction(teacher));
          dispatch(push("/classes"));
        } else {
          alert("ログインする権限がありません。");
        }
      })
      .catch(() => {
        alert("メールアドレスまたはパスワードが間違っています。");
      })
      .finally(() => {
        dispatch(setLoadingAction(false));
      });
  };
};

export const getStudent = (studentId: string): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios.get(`/students/${studentId}`).then((res) => {
      const student: StudentEntity = res.data;
      dispatch(setStudentAction(student));
      dispatch(setLoadingAction(false));
    });
  };
};

export const getPhraseAchievements = (
  studentId: string,
  page: number,
  limit: number
): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(
        `/students/${studentId}/phrase/achievements?page=${page}&limit=${limit}`
      )
      .then((res) => {
        const phraseAchievements: PhraseAchievementEntity[] = res.data;
        dispatch(setPhraseAchievementAction(phraseAchievements));
        dispatch(setLoadingAction(false));
      });
  };
};

export const getLongSentenceAchievements = (
  studentId: string,
  page: number,
  limit: number
): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios
      .get(
        `/students/${studentId}/longSentence/achievements?page=${page}&limit=${limit}`
      )
      .then((res) => {
        const longSentenceAchievements: LongSentenceAchievementEntity[] =
          res.data;
        dispatch(setLongSentenceAchievementAction(longSentenceAchievements));
        dispatch(setLoadingAction(false));
      });
  };
};
