import { Pagination } from "../../../components/Pagination";
import { PhraseAchievementEntity } from "../../../types";
import { PhraseResultListRow } from "./PhraseResultListRow";

type Props = {
  phraseAchievements: PhraseAchievementEntity[];
  onePageDisplayItems: number;
  itemSize: number;
  handleGetPhraseAchievements: (page: number) => void;
};

export const PhraseResultList = (props: Props) => {
  const listRows = props.phraseAchievements.map(
    (value: PhraseAchievementEntity, index: number) => (
      <PhraseResultListRow key={index} phraseAchievement={value} />
    )
  );

  const pageCount = Math.ceil(props.itemSize / props.onePageDisplayItems);

  return (
    <div className="relative">
      <div className="bg-white rounded-xl px-10 py-5 mx-10">
        {pageCount > 1 ? (
          <div className="flex flex-row-reverse pb-3">
            <Pagination
              pageCount={pageCount}
              handleGetItem={props.handleGetPhraseAchievements}
            />
          </div>
        ) : (
          <div className="pb-3"></div>
        )}
        <div className="overflow-x-scroll">
          <table className="w-full border-collapse whitespace-nowrap">
            <thead>
              <tr>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white relative">
                  カテゴリ
                </th>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white">
                  項目
                </th>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white">
                  文章
                </th>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white">
                  タイプ
                </th>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white">
                  開始日時
                </th>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white">
                  回数
                </th>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white">
                  スピーキング結果
                </th>
              </tr>
            </thead>
            <tbody>{listRows}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
