type Props = {
  subMessage: string;
};

export const SubMessage = (props: Props) => {
  return (
    <div className="flex justify-center items-center my-3">
      <p className="text-4xl">{props.subMessage}</p>
    </div>
  );
};
