import { RootEntity } from "../../types";

const initialState: RootEntity = {
  samples: [],
  users: {
    teacher: null,
    student: null,
    phraseAchievements: [],
    longSentenceAchievements: [],
  },
  classes: [],
  filteredClasses: [],
  year: { years: [], selectedYear: 0 },
  phrase: {
    studentPhraseListeningAchievements: [],
    studentPhraseSpeakingAchievements: [],
    phraseCategories: [],
    phrases: [],
    phraseAchievementCount: 0,
  },
  longSentence: {
    studentLongSentenceListeningAchievements: [],
    studentLongSentenceSpeakingAchievements: [],
    longSentenceCategories: [],
    longSentences: [],
    longSentenceAchievementCount: 0,
  },
  writing: {
    studentWritingAchievements: [],
    writingAchievementCount: 0,
  },
  isLoading: false,
};

export default initialState;
