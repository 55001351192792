import { useState } from "react";
import useSound from "use-sound";
import { LongSentenceAchievementEntity } from "../../../types";
import { getJSTDate } from "../../../utils/conversion";

type Props = {
  longSentenceAchievement: LongSentenceAchievementEntity;
};

export const LongSentenceResultListRow = (props: Props) => {
  const [play, { stop }] = useSound(
    props.longSentenceAchievement.audioFileUrl,
    {
      onend: () => {
        setIsPlaying(false);
      },
    }
  );

  const [playResult, { pause }] = useSound(
    props.longSentenceAchievement.recordedAudioFileUrl!,
    {
      onend: () => {
        setIsPlayingResult(false);
      },
    }
  );

  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlayingResult, setIsPlayingResult] = useState(false);

  const onClickPlay = () => {
    play();
    setIsPlaying(true);
  };

  const onClickStop = () => {
    stop();
    setIsPlaying(false);
  };

  const onClickPlayResult = () => {
    playResult();
    setIsPlayingResult(true);
  };

  const onClickPauseResult = () => {
    pause();
    setIsPlayingResult(false);
  };

  return (
    <tr>
      <td className="px-2 pr-3 h-14 leading-none text-center border-b border-r border-d4">
        {props.longSentenceAchievement.level.name}
      </td>
      <td className="px-2 py-4 text-center border-b border-r border-d4">
        {props.longSentenceAchievement.theme.name}
      </td>
      <td className="px-2 w-auto py-4 text-center border-b border-r border-d4">
        {props.longSentenceAchievement.title}
      </td>
      <td className="px-2 py-4 stext-center border-b border-r border-d4">
        {isPlaying ? (
          <div className="flex justify-center items-center text-15">
            <img
              onClick={onClickStop}
              className="w-10 cursor-pointer"
              src="/assets/icon-pause.png"
              alt=""
            />
          </div>
        ) : (
          <div className="flex justify-center items-center text-15">
            <img
              onClick={onClickPlay}
              className="w-10 cursor-pointer"
              src="/assets/icon-play.png"
              alt=""
            />
          </div>
        )}
      </td>
      <td className="px-2 py-4 text-center border-b border-r border-d4">
        {getJSTDate(props.longSentenceAchievement.startedAt)}
      </td>
      <td className="px-2 py-4 text-center border-b border-r border-d4">
        {props.longSentenceAchievement.count + "回"}
      </td>
      <td className="px-2 py-4 text-center border-b border-d4">
        <div className="flex justify-center items-center text-15">
          {!props.longSentenceAchievement.score
            ? "-"
            : Math.floor(props.longSentenceAchievement.score) + "点"}
          {!props.longSentenceAchievement
            .recordedAudioFileUrl ? null : isPlayingResult ? (
            <div className="flex justify-center items-center text-15">
              <img
                onClick={onClickPauseResult}
                className="ml-7 w-10 cursor-pointer"
                src="/assets/icon-pause.png"
                alt=""
              />
            </div>
          ) : (
            <div className="flex justify-center items-center text-15">
              <img
                onClick={onClickPlayResult}
                className="ml-7 w-10 cursor-pointer"
                src="/assets/icon-play.png"
                alt=""
              />
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};
