import { Link, useParams } from "react-router-dom";

type Props = {
  parentType: { id: string; name: string };
  childType: { id: string; name: string };
  className: string;
};

export const CategoryItem = (props: Props) => {
  const params = useParams();

  return (
    <label className="underline font-bold block mb-4 ml-5">
      <Link
        to={`/classes/${params.class_id}/phrase/categories/${props.parentType.id}/${props.childType.id}`}
        state={{
          className: props.className,
          parentTypeName: props.parentType.name,
          childTypeName: props.childType.name,
        }}
      >
        {props.childType.name}
      </Link>
    </label>
  );
};
