export type ActionType = SetLoadingAction;

interface SetLoadingAction {
  type: "SET_LOADING";
  payload: boolean;
}

export const SET_LOADING = "SET_LOADING";
export const setLoadingAction = (isLoading: boolean) => {
  return {
    type: SET_LOADING,
    payload: isLoading,
  };
};
