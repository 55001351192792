export const EmailInputField = (props: any) => {
  return (
    <div className="mb-2">
      <label
        htmlFor="email"
        className="block text-sm tab:text-lg relative text-black"
      >
        メールアドレス
        <input
          id="email"
          name="email"
          type="email"
          className="tab:text-base border w-full px-4 tab:px-5 py-3 tab:py-4 mt-1.5 rounded-md"
          placeholder="test@example.com"
          {...props.register("email", {
            required: true,
          })}
        />
      </label>
    </div>
  );
};
