import { axios } from "../../lib/axios";
import { ClassEntity } from "../../types";
import { setFilteredClassesAction } from "../filteredClasses/actions";
import { setLoadingAction } from "../loading/actions";
import { setSelectedYearAction, setYearsAction } from "../year/actions";
import { setClassesAction } from "./actions";

export const getClasses = (): any => {
  return async (dispatch: React.Dispatch<unknown>) => {
    dispatch(setLoadingAction(true));
    await axios.get("/classes").then((res) => {
      const classes: ClassEntity[] = res.data;

      // 最新年度を計算
      const allYears = classes.map((value: ClassEntity) => value.year);
      const years = allYears
        .filter((year, index) => allYears.indexOf(year) === index)
        .sort((a, b) => b - a);
      const latestYear = years[0];

      const filteredClasses = classes.filter(
        (value) => value.year === latestYear
      );
      dispatch(setYearsAction(years));
      dispatch(setSelectedYearAction(latestYear));
      dispatch(setFilteredClassesAction(filteredClasses));
      dispatch(setClassesAction(classes));
      dispatch(setLoadingAction(false));
    });
  };
};
