import { useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { FormState } from "../types";

type Props = {
  register: UseFormRegister<FormState>;
};

export const PasswordInputField = (props: Props) => {
  const [isRevealPassword, setIsRevealPassword] = useState(false);

  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  };

  return (
    <div className="mb-2">
      <label
        htmlFor="password"
        className="block max-w-sm font-bold text-lg relative text-black"
      >
        設定したいパスワード
        <input
          id="password"
          type={isRevealPassword ? "text" : "password"}
          className="text-base border w-full px-5 py-4 mt-1.5 rounded-md"
          required
          {...props.register("password", {
            pattern: /^[0-9]*$/,
            minLength: 4,
            maxLength: 4,
          })}
        />
        <span onClick={togglePassword} role="presentation">
          {isRevealPassword ? (
            <i className="icon-eye absolute is-show" />
          ) : (
            <i className="icon-eye absolute" />
          )}
        </span>
      </label>
    </div>
  );
};
