type Props = {
  statusCode: string;
};

export const StatusCode = (props: Props) => {
  return (
    <div className="flex justify-center items-center mt-14">
      <p className="text-8xl">{props.statusCode}</p>
    </div>
  );
};
