import { Link, useParams } from "react-router-dom";

type Props = {
  level: { id: string; name: string };
  theme: { id: string; namePrefix: string; name: string };
  className: string;
};

export const CategoryItem = (props: Props) => {
  const params = useParams();

  return (
    <label className="underline font-bold block mb-4 ml-5">
      <Link
        to={`/classes/${params.class_id}/long_sentence/categories/${props.level.id}/${props.theme.id}`}
        state={{
          className: props.className,
          levelName: props.level.name,
          themeName: props.theme.name,
        }}
      >
        {props.theme.name}
      </Link>
    </label>
  );
};
