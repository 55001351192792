import { SampleEntity } from "../../features/sample";

export type ActionType =
  | EditSampleNameAction
  | EditSampleTitleAction
  | SetSamplesAction;

interface EditSampleNameAction {
  type: "EDIT_SAMPLE_NAME";
  payload: { title: string };
}

interface EditSampleTitleAction {
  type: "EDIT_SAMPLE_TITLE";
  payload: { name: string };
}

interface SetSamplesAction {
  type: "SET_SAMPLES";
  payload: { samples: SampleEntity[] };
}

export const EDIT_SAMPLE_NAME = "EDIT_SAMPLE_NAME";
export const editSampleNameAction = (sampleName: string) => {
  return {
    type: EDIT_SAMPLE_NAME,
    payload: { name: sampleName },
  };
};

export const EDIT_SAMPLE_TITLE = "EDIT_SAMPLE_TITLE";
export const editSampleTitleAction = (sampleTitile: string) => {
  return {
    type: EDIT_SAMPLE_TITLE,
    payload: { title: sampleTitile },
  };
};

export const SET_SAMPLES = "SET_SAMPLES";
export const setSamplesAction = (samples: SampleEntity[]) => {
  return {
    type: SET_SAMPLES,
    payload: samples,
  };
};
