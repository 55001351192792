import { Link } from "react-router-dom";
import { StudentWritingAchievementEntity } from "../../../types";
import { getJSTDate } from "../../../utils/conversion";

type Props = {
  studentWritingAchievement: StudentWritingAchievementEntity;
};

export const WritingResultListRow = (props: Props) => {
  return (
    <tr>
      <td className="whitespace-nowrap pr-3 h-14 leading-none text-center border-b border-r border-d4">
        <p className="flex justify-between items-center text-15">
          {props.studentWritingAchievement.lastName +
            " " +
            props.studentWritingAchievement.firstName}
          <Link
            to={`/students/${props.studentWritingAchievement.id}`}
            className="button flex justify-center items-center px-6 py-1 ml-10 text-sm"
          >
            <img
              className="transform rotate-90 mr-2"
              src="/assets/icon-arrow_up.svg"
              alt=""
            />
            生徒情報
          </Link>
        </p>
      </td>
      <td className="py-4 px-2 text-left border-b border-r border-d4">
        {props.studentWritingAchievement.translatedSentence}
      </td>
      <td className="py-4 px-2 text-left border-b border-r border-d4">
        {props.studentWritingAchievement.answeredSentence}
      </td>
      <td className="whitespace-nowrap py-4 px-2 text-center border-b border-d4">
        {getJSTDate(props.studentWritingAchievement.answeredAt)}
      </td>
    </tr>
  );
};
