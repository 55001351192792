import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createReduxHistoryContext } from "redux-first-history";
import thunk from "redux-thunk";
import { samplesReducer } from "../sample/reducers";
import { createBrowserHistory } from "history";
import { usersReducer } from "../users/reducers";
import { classesReducer } from "../classes/reducers";
import { phraseReducer } from "../phrase/reducers";
import { longSentenceReducer } from "../longSentence/reducers";
import { loadingReducer } from "../loading/reducers";
import { yearReducer } from "../year/reducers";
import { filteredClassesReducer } from "../filteredClasses/reducers";
import { writingReducer } from "../writing/reducers";

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

export const store = configureStore({
  reducer: combineReducers({
    router: routerReducer,
    samples: samplesReducer,
    users: usersReducer,
    classes: classesReducer,
    filteredClasses: filteredClassesReducer,
    year: yearReducer,
    phrase: phraseReducer,
    longSentence: longSentenceReducer,
    writing: writingReducer,
    isLoading: loadingReducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware, thunk),
});

export const history = createReduxHistory(store);
