import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Header } from "../../../components/Header";
import { RootEntity } from "../../../types";
import { PageTitle } from "../../../components/PageTitle";
import useLocationChange from "../../../utils/location";
import Loading from "../../../components/Loading";
import { WritingResultList } from "../components/WritingResultList";
import {
  getStudentWritingAchievements,
  getWritingAchievementCount,
} from "../../../reducks/writing/operations";
import { clearWritingAction } from "../../../reducks/writing/actions";

type State = {
  className: string;
};

const ONE_PAGE_DISPLAY_ACIEVEMENTS = 30; //1ページに表示する学習履歴の数

export const WritingDetails = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!params.class_id) {
      navigate("/404");
    } else {
      dispatch(
        getStudentWritingAchievements(
          params.class_id,
          1,
          ONE_PAGE_DISPLAY_ACIEVEMENTS
        )
      );
      dispatch(getWritingAchievementCount(params.class_id));
    }
  }, [dispatch]);

  const studentWritingAchievements = useSelector(
    (state: RootEntity) => state.writing.studentWritingAchievements
  );
  const writingAchievementCount = useSelector(
    (state: RootEntity) => state.writing.writingAchievementCount
  );
  const isLoading = useSelector((state: RootEntity) => state.isLoading);

  const location = useLocation();
  const state = location.state as State;

  const handleGetWritingAchievements = (page: number) => {
    if (!params.class_id) {
      navigate("/404");
    } else {
      dispatch(
        getStudentWritingAchievements(
          params.class_id,
          page,
          ONE_PAGE_DISPLAY_ACIEVEMENTS
        )
      );
    }
  };

  // ページ遷移時にstoreからフレーズ情報を削除する
  useLocationChange(() => {
    dispatch(clearWritingAction());
  });

  return (
    <div>
      <Loading isLoading={isLoading} />
      <PageTitle title="ライティング詳細 - TiiFa LS" />
      <Header />
      <div className="teacher min-h-screen bg-e5 flex">
        <div className="w-full pc:ml-4 pc:border-l border-c pc:pl-10 pb-8">
          <div className="w-60 h-14">
            <Link to="/classes">
              <div className="pl-4 ml-8 -mt-4 pt-4 w-54 h-14">
                <img
                  className="transform rotate-180 inline-block mr-2 mb-1"
                  src="/assets/icon-arrow.png"
                  alt=""
                />
                クラスの一覧へ戻る
              </div>
            </Link>
          </div>
          <h2 className="text-2xl font-bold text-center mb-5">
            {`${state.className} ライティング 学習状況`}
          </h2>
          <WritingResultList
            studentWritingAchievements={studentWritingAchievements}
            onePageDisplayItems={ONE_PAGE_DISPLAY_ACIEVEMENTS}
            itemSize={writingAchievementCount}
            handleGetWritingAchievements={handleGetWritingAchievements}
          />
        </div>
      </div>
    </div>
  );
};
