import { StudentPhraseSpeakingAchievementEntity } from "../../../types";
import { SpeakingResultListRow } from "./SpeakingResultListRow";

type Props = {
  studentSpeakingAchievements: StudentPhraseSpeakingAchievementEntity[];
};

export const SpeakingResultList = (props: Props) => {
  const listRows = props.studentSpeakingAchievements.map(
    (value: StudentPhraseSpeakingAchievementEntity, index: number) => (
      <SpeakingResultListRow key={index} studentSpeakingAchievement={value} />
    )
  );

  return (
    <div className="relative">
      <div className="bg-white rounded-xl px-10 py-12 mx-10">
        <div className="overflow-x-scroll">
          <table className="w-full border-collapse whitespace-nowrap">
            <thead>
              <tr>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white relative">
                  生徒名
                </th>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white">
                  本日の
                  <br />
                  回数
                </th>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white">
                  昨日の
                  <br />
                  回数
                </th>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white">
                  直近1週間 <br />
                  の回数
                </th>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white">
                  直近1ヶ月 <br />
                  の回数
                </th>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white">
                  累計の <br />
                  回数
                </th>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white border-r border-white">
                  最高得点
                </th>
                <th className="h-16 px-5 leading-tight font-bold bg-blue text-white">
                  直近の学習日時
                </th>
              </tr>
            </thead>
            <tbody>{listRows}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
