import { useState } from "react";

export const PasswordInputField = (props: any) => {
  const [isRevealPassword, setIsRevealPassword] = useState(false);

  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  };

  return (
    <div className="mb-2">
      <label
        htmlFor="password"
        className="block text-sm tab:text-lg relative text-black"
      >
        パスワード
        <input
          id="password"
          name="password"
          type={isRevealPassword ? "text" : "password"}
          className="tab:text-base border w-full px-4 tab:px-5 py-3 tab:py-4 mt-1.5 rounded-md"
          {...props.register("password", {
            required: true,
          })}
        />
        <span onClick={togglePassword} role="presentation">
          {isRevealPassword ? (
            <i className="icon-eye absolute is-show" />
          ) : (
            <i className="icon-eye absolute" />
          )}
        </span>
      </label>
    </div>
  );
};
