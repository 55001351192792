import { useDispatch, useSelector } from "react-redux";
import { Header } from "../../../components/Header";
import Loading from "../../../components/Loading";
import { PageTitle } from "../../../components/PageTitle";
import { clearClassesAction } from "../../../reducks/classes/actions";
import useLocationChange from "../../../utils/location";
import { ListContent } from "../components/ListContent";
import { ClassEntity, RootEntity } from "../../../types";
import { SelectYears } from "../components/SelectYears";
import { useEffect } from "react";
import { getClasses } from "../../../reducks/classes/operations";
import { setSelectedYearAction } from "../../../reducks/year/actions";
import { setFilteredClassesAction } from "../../../reducks/filteredClasses/actions";

export const ClassList = () => {
  const dispatch = useDispatch();

  const classes = useSelector((state: RootEntity) => state.classes);
  const selectedYear = useSelector(
    (state: RootEntity) => state.year.selectedYear
  );
  const filteredClasses = useSelector(
    (state: RootEntity) => state.filteredClasses
  );

  useEffect(() => {
    dispatch(getClasses());
  }, [dispatch]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSelectedYearAction(Number(e.target.value)));
    dispatch(
      setFilteredClassesAction(
        classes.filter(
          (value: ClassEntity) => value.year === Number(e.target.value)
        )
      )
    );
  };

  // ページ遷移時にstoreからクラス情報を削除する
  useLocationChange(() => {
    dispatch(clearClassesAction());
  });

  const isLoading = useSelector((state: RootEntity) => state.isLoading);

  return (
    <div>
      <Loading isLoading={isLoading} />
      <PageTitle title="クラス一覧 - TiiFa LS" />
      <Header />
      <div className="teacher min-h-screen bg-e5 flex">
        <div className="w-full pc:ml-4 pc:border-l border-c pc:pl-10 pb-8">
          <h3 className="text-xl font-bold mb-6 mx-10 border-l-6 border-blue flex py-1 pl-4">
            {selectedYear}年度のクラス一覧
          </h3>
          <SelectYears handleChange={handleChange} />
          <ListContent classes={filteredClasses} />
        </div>
      </div>
    </div>
  );
};
